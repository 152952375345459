"use client";
import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {text, title} from "@/styles/globalStyleVars";

const Title = ({
  text,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  textTransform,
  margin,
  padding,
  borderColor,
  varient,
  center,
  classname,
  marginSm,
  width,
  type,border, animClass
}) => {
  return (
    <StyledTitle
      className={`title ${classname} ${border ? 'border-bottom' : ''}`}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      lineHeight={lineHeight}
      LetterSpacing={letterSpacing}
      textTransform={textTransform}
      margin={margin}
      padding={padding}
      varient={varient}
      center={center}
      marginSm={marginSm}
      width={width}
      borderColor={borderColor}

    >
      {
        (() => {
          switch (type) {
            case 'h1':
              return <h1 className={` ${animClass ? animClass : 'split-up'} `}>{ReactHtmlParser(text)}</h1>;
            case 'h2':
              return <h2 className={` ${animClass ? animClass : 'split-up'} `}>{ReactHtmlParser(text)}</h2>;
            case 'h3':
              return <h3 className={` ${animClass ? animClass : 'split-up'}  `}>{ReactHtmlParser(text)}</h3>;
            case 'h4':
              return <h4 className={` ${animClass ? animClass : 'split-up'}  `}>{ReactHtmlParser(text)}</h4>;
            case 'h5':
              return <h5 className={` ${animClass ? animClass : 'split-up'} `}>{ReactHtmlParser(text)}</h5>;
            case 'h6':
              return <h6 className={` ${animClass ? animClass : 'split-up'} `}>{ReactHtmlParser(text)}</h6>;
            default:
              return <p className={` ${animClass ? animClass : 'split-up'}  `}>{ReactHtmlParser(text)}</p>;
          }
        })()
      }

    </StyledTitle>
  );
};

const StyledTitle = styled.div`
  margin: ${(props) => props.margin || "0px"};
  position: relative;
  width: ${(props) => props.width || "fit-content"};
  text-align: ${(props) => (props?.center ? "center" : "")};
  padding: ${(p) => p.padding};



  h1,h2,h3,h4,h5,h6,p{
    color: ${(p) => p.color ? p.color : text};
    position: relative;
    text-transform: none;
    font-weight: ${(props) => (props?.fontWeight ? props?.fontWeight : "400")};;
    &.border{
      padding-bottom: 20px;
      border-bottom: 1px solid ${(p) => p.padding ? p.padding : 'rgba(54,50,49,0.1)'};
    }
  }

  @media (max-width: 767px) {
    padding: 0;
    margin: ${(p) => p.marginSm};
  }
`;

export default React.memo(Title);
