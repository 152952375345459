import React, { createContext, useContext } from 'react';

// Create a context for showSetting
const ShowSettingContext = createContext();

// Export a custom hook for easier access
export const useShowSetting = () => useContext(ShowSettingContext);

// Create a Provider component
export const ShowSettingProvider = ({ children, showSetting }) => {
    return (
        <ShowSettingContext.Provider value={showSetting}>
            {children}
        </ShowSettingContext.Provider>
    );
};
