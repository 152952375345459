import React from 'react';
import styled from 'styled-components';
import {hover, text, white} from '@/styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import {Img} from "@/components/common/ui/Img";
import Social from "@/components/common/Social";
import Link from "next/link";
import Title from "@/components/common/ui/Title";
import useContainerOffset from "@/components/hooks/useContainerOffset";



const ImageSocialBox = ({title, subtitle, description, img, showSetting, offset, dataSpeed}) => {

    const letoffset = useContainerOffset('.container');


    const phoneNumbers = showSetting?.address?.[0]?.data?.scholarship_deadline?.split(", ");
    const emails = showSetting?.address?.[0]?.data?.position?.split(", ");
    const regex = /(\+?\d[\d\s]+)\s*\((\w+)\)/;

    return (

        <StyeldSection offset={offset} className={'slider_component_latest'}>

            <div className={'single_image_image'}>
                <div className="content_wrap" style={{height: '100%'}}>
                    <div className="single_image_image_wrp ">
                        <Img alt={title} parallax parallaxSpeed={dataSpeed ? dataSpeed: 0.8} offset={0} src={img ? img : '/images/dynamic/common/landing-form.jpg'}/>
                        <div className="single_content_wrp" style={{paddingLeft: offset ? offset+ 15 : letoffset + 15}}>
                            <div className="content-info">
                                <Title fontWeight={500} type={'h5'} animClass={'fade-up'} text={title ? reactHtmlParser(title) : 'How Can We Assist You Today?'}/>

                                <p className={'fade-up'}>{subtitle ? reactHtmlParser(subtitle) : reactHtmlParser('Let us know your questions or concerns, and we’ll guide<br/> you towards the best solutions.')}</p>

                            </div>
                            <div className="content-address">
                                {/*{*/}
                                {/*    description ?*/}
                                {/*        <>*/}
                                {/*            {reactHtmlParser(description) }*/}
                                {/*        </>*/}


                                {/*        :*/}

                                {/*        <>*/}
                                {/*            <ul>*/}
                                {/*                <li>*/}
                                {/*                    <Link href={'mail:info@maximuseducation.com.au'}>info@maximuseducation.com.au</Link><br/>*/}

                                {/*                </li>*/}
                                {/*                <li>*/}
                                {/*                    <Link href={'tel:+61496037299'}>+61 406 037 299 (AU)</Link><br/>*/}

                                {/*                </li>*/}
                                {/*                <li>*/}
                                {/*                    <Link href={'tel:+8801329665788'}>+880 1329 665 788 (BD)</Link>*/}

                                {/*                </li>*/}
                                {/*            </ul>*/}
                                {/*        </>*/}
                                {/*}*/}

                                {
                                    showSetting &&
                                    showSetting?.address?.[0]?.data?.body &&
                                    <>
                                        <Link prefetch={true} target={'_blank'} href={showSetting?.address?.[0]?.data?.google_map ? showSetting?.address?.[0]?.data?.google_map : '#'}>
                                            {reactHtmlParser(showSetting?.address?.[0]?.data?.body)}
                                        </Link><br/>
                                    </>
                                }
                                {
                                    showSetting &&
                                    emails?.map((e, index) => {
                                        return (
                                            <Link prefetch={true} key={index} href={`mailto:${e}`}>{reactHtmlParser(e)}</Link>

                                        )
                                    })

                                }
                                <br/>

                                {
                                    showSetting &&
                                    <div className={'d-flex flex-column'}>
                                        {
                                            phoneNumbers?.map((e, index) => {
                                                const matches = e?.match(regex);

                                                return (
                                                    <Link style={{display: 'inline-block'}} prefetch={true} key={index} href={`tel:${matches ? matches[1].trim() : e}`}>{reactHtmlParser(e)}</Link>

                                                )
                                            })

                                        }
                                    </div>

                                }


                                <br/>
                                {
                                    showSetting &&
                                    <Social bg={white} iconhover={white} iconColor={text} hoverbg={hover} facebook={showSetting?.address?.[0]?.data?.facebook} linkedin={showSetting?.address?.[0]?.data?.linkedIn} youtube={showSetting?.address?.[0]?.data?.youtube} pinterest={showSetting?.address?.[0]?.data?.tikTok} instra={showSetting?.address?.[0]?.data?.instagram}/>

                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </StyeldSection>

    );

};

const StyeldSection = styled.section`
    overflow: hidden;
    height: 100%;
    position: relative;

    .global-image{
       
        img{
            top: -100px !important;
            height: calc(100% + 150px) !important;
            width: calc(100% + 50px) !important;
        }
    }
    &:after {
        position: absolute;
        inset: 0;

    }


    .single_image_image {
        height: 100%;
    }

    .content_wrap, .single_image_image_wrp {
        height: 100%;
    }

    .single_image_image {
        .content_wrap {
            .single_image_image_wrp {
                position: relative;
                padding-top: calc(636 / 570 * 100%);

                .single_content_wrp {
                    position: absolute;
                    inset: 0;
                    background: transparent;
       
                    padding: 70px 70px 70px ${(props) => (props.offset ? props.offset + 'px' : '70px')};
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h4 {
                        margin-bottom: 20px;
                    }

                    h4, p {
                        color: ${white}
                    }

                    a {
                        color: ${white}

                    }

                    ul {
                        margin-bottom: 10px;

                        li {
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                
                @media(max-width: 767px){
                        padding-top: calc(654 / 370 * 100%) !important;
                }
            }
        }
    }

    h5{
        font-size: 30px;
        line-height: 40px;
        //letter-spacing: -0.32px;
        color: white;
    }

`;


export default React.memo(ImageSocialBox);