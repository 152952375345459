import { Form } from 'react-bootstrap';

const TextInput = ({ name, placeholder, type = "text", register, validation, error, as, value }) => (
    <div className="form-group">
        <Form.Group>
            <Form.Control
                value={value}
                as={as} // This allows switching between input types (e.g., 'textarea')
                className={error ? 'has-error form-control-lg' : 'form-control-lg'}
                {...register(name, validation)} // Spread register for form handling
                type={type}
                placeholder={placeholder}
            />
            {error && <span className="error-message form-error">{error.message}</span>}
        </Form.Group>
    </div>
);

export default TextInput;
